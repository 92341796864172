<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel">
    <v-card>
      <v-toolbar dark :color="options.color" dense flat>
        <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message" class="pa-4">
        <span>{{ message }}</span>
        <v-form ref="form" v-model="valid">
          <v-text-field class="mt-2" hide-details="auto" v-model="folder" :rules="[rules.req, folderNameValidation]" filled dense placeholder="Nombre de la carpeta"></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="error" text @click.native="cancel">Cancelar</v-btn>
        <v-btn color="primary darken-1" text @click.native="agree">Crear</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { req } from "@/utils/validations.js";
export default {
  data: () => ({
    folder:'',
    dialog: false,
    resolve: null,
    valid: true,
    reject: null,
    message: null,
    title: null,
    rules: {
      req,
    },
    options: {
      color: 'primary',
      width: 490,
      zIndex: 200
    }
  }),
  computed: {
    folderNameValidation() {
      return (v) => {
        const reservedNames = ['con', 'prn', 'aux', 'nul', 'com1', 'com2', 'com3', 'com4',
                              'com5', 'com6', 'com7', 'com8', 'com9', 'lpt1', 'lpt2',
                              'lpt3', 'lpt4', 'lpt5', 'lpt6', 'lpt7', 'lpt8', 'lpt9'];
        if (reservedNames.includes(v)) {
          return 'Nombre de carpeta reservado';
        }
        const illegalCharsRegex = /[\\/:"*?<>|]/;
        if (illegalCharsRegex.test(v)) {
          return 'Caracteres no permitidos: \\ / : * ? " < > |';
        }
        return true;
      };
    },
  },
  methods: {
    open(title, message, options) {
      this.folder = ''
      this.dialog = true
      this.valid = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      if (this.$refs.form.validate()){
        this.resolve(this.folder)
        this.dialog = false
      }
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    },
  }
}
</script>